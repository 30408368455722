import RedirectPrompt from '$blocks/RedirectPrompt'
import Breadcrumb from '$components/Breadcrumb/v2'
import FooterAction from '$components/FooterAction'
import Loading from '$components/Loading'
import PasswordManagement from '$components/PasswordManagement'
import { useRedirectProxy, useRouteSummary } from '$contexts/RouteContext/hooks'
import { usePasswordPolicyService, useUserService } from '$hooks/services'
import { checkPolicies } from '$pages/PasswordPolicy/constants'
import { redirect, ROUTE_NAMES } from '$router/config'
import { Button, Spinner } from '@genie-fintech/ui/components'
import { useMount } from 'ahooks'
import { FormEvent, useCallback, useMemo, useState } from 'react'

const UpdatePassword = () => {
  const [updatedPwd, setUpdatedPwd] = useState('')

  const [errorMsg, setErrorMsg] = useState('')

  const {
    route: { params, queryParams }
  } = useRouteSummary()

  const { userId } = params

  const { source, appId } = queryParams

  const proxyRedirect = useRedirectProxy()

  const { fetchPasswordPolicy, passwordPolicy, fetchingPasswordPolicy } =
    usePasswordPolicyService()

  const { updatePasswordAsync, updatingPassword } = useUserService()

  const enabled_policies = passwordPolicy.filter(d => d.is_enabled)

  const isPwdValid = checkPolicies(enabled_policies, updatedPwd)

  const redirectInfo: Parameters<typeof redirect> = useMemo(() => {
    if (appId && source === ROUTE_NAMES.APP_USER_DETAIL) {
      return [ROUTE_NAMES.APP_USER_DETAIL, { params: { appId, userId } }]
    }

    if (source === ROUTE_NAMES.GLOBAL_APP_USER_DETAIL) {
      return [
        ROUTE_NAMES.GLOBAL_APP_USER_DETAIL,
        { params: { appUserId: userId } }
      ]
    }

    return [
      ROUTE_NAMES.GLOBAL_DASHBOARD_USER_DETAIL,
      { params: { dashboardUserId: userId } }
    ]
  }, [appId, source, userId])

  useMount(() => fetchPasswordPolicy())

  const onChangePassword = useCallback((pwd: string) => {
    setUpdatedPwd(pwd)
    setErrorMsg('')
  }, [])

  const onCancel = useCallback(() => {
    proxyRedirect(...redirectInfo)
  }, [proxyRedirect, redirectInfo])

  const onSuccess = useCallback(() => {
    redirect(...redirectInfo)
  }, [redirectInfo])

  const onSaveChanges = useCallback(() => {
    if (!userId) return

    if (!isPwdValid) {
      setErrorMsg('Password must be valid with all password policies')
      return
    }

    return updatePasswordAsync({
      isAdmin: !source,
      userId,
      password: updatedPwd
    }).then(onSuccess)
  }, [isPwdValid, source, userId, updatePasswordAsync, updatedPwd, onSuccess])

  const onSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault()

      onSaveChanges()
    },
    [onSaveChanges]
  )

  if (fetchingPasswordPolicy) return <Loading />

  return (
    <>
      <Breadcrumb
        category={(() => {
          if (source === ROUTE_NAMES.APP_USER_DETAIL) return ROUTE_NAMES.APPS
          if (source === ROUTE_NAMES.GLOBAL_APP_USER_DETAIL)
            return ROUTE_NAMES.GLOBAL_APP_USERS
          return ROUTE_NAMES.GLOBAL_DASHBOARD_USERS
        })()}
        data={(() => {
          const arr: { name: string; path?: ROUTE_NAMES }[] = [
            { name: 'User Details' }
          ]
          if (source === ROUTE_NAMES.APP_USER_DETAIL)
            arr.unshift({ name: 'User Details', path: ROUTE_NAMES.APP_USERS })
          return arr
        })()}
      />

      <form onSubmit={onSubmit} className="flex max-w-[1056px] mx-auto">
        <PasswordManagement
          label="Update New Password"
          value={updatedPwd}
          onChange={onChangePassword}
          passwordPolicies={enabled_policies}
          error={errorMsg}
        />

        <FooterAction>
          <article className="flex justify-end w-full max-w-[1056px] gap-2 mx-auto">
            <Button
              disabled={updatingPassword}
              styleVariants={{ type: 'text' }}
              onClick={onCancel}
            >
              Cancel
            </Button>

            <Button type="submit" disabled={!updatedPwd || updatingPassword}>
              {updatingPassword && <Spinner />}
              Save Changes
            </Button>
          </article>
        </FooterAction>
      </form>

      <RedirectPrompt
        type="edit"
        isDirty={!!updatedPwd}
        isValid={isPwdValid}
        onConfirm={() => {
          return new Promise(resolve => {
            onSaveChanges()?.then(resolve)
          })
        }}
      />
    </>
  )
}

export default UpdatePassword
