import { cn } from '$app/utils'
import AttachAppToUser from '$blocks/AttachAppToUser'
import RedirectPrompt from '$blocks/RedirectPrompt'
import Asterisk from '$components/Asterisk'
import Breadcrumb from '$components/Breadcrumb/v2'
import FooterAction from '$components/FooterAction'
import Select from '$components/Select'
import { useRedirectProxy } from '$contexts/RouteContext/hooks'
import {
  useGlobalDashboardUserService,
  usePasswordPolicyService
} from '$hooks/services'
import { useCountryStore } from '$hooks/stores'
import PasswordManagement from '$components/PasswordManagement'
import { checkPolicies } from '$pages/PasswordPolicy/constants'
import { redirect, ROUTE_NAMES } from '$router/config'
import { TID } from '$services/api'
import { defaultBackground, defaultBorder } from '$styles/common.css'
import { Avatar, Button, Spinner } from '@genie-fintech/ui/components'
import { BaseText } from '@genie-fintech/ui/components/fields'
import { BaseText as BaseHookFieldText } from '@genie-fintech/ui/components/hook-fields'
import { themeVars } from '@genie-fintech/ui/style/theme'
import { zodResolver } from '@hookform/resolvers/zod'
import { useMount } from 'ahooks'
import { useCallback, useMemo, useState } from 'react'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { z } from 'zod'
import { ACCOUNT_TYPE } from '../constants'
import DashboardAdminMessage from '$components/DashboardAdminMessage'
import { markedDefaultKey } from '@genie-fintech/ui/style/theme/colors/functions'

const { colors } = themeVars

const schema = z.object({
  name: z.string().trim().min(1, 'Requried!'),
  role: z
    .object({
      value: z.string().trim(),
      label: z.string().trim()
    })
    .refine(d => !!d.value.trim(), 'Requried!'),
  type: z
    .object({
      value: z.string().trim(),
      label: z.string().trim()
    })
    .refine(d => !!d.value.trim(), 'Requried!'),
  email: z.string().trim().min(1, { message: 'Required!' }).email(),
  phone_no: z.string().trim(),
  phone_code: z.object({
    value: z.string().trim(),
    label: z.string().trim()
  })
})

type TFormValues = z.infer<typeof schema>

const DEFAULT_FORM_VALUES: TFormValues = {
  name: '',
  type: { label: '', value: '' },
  role: { label: '', value: '' },
  email: '',
  phone_code: { label: '', value: '' },
  phone_no: ''
}

const GlobalDashboardUserCreate = () => {
  const [step, setStep] = useState(1)

  const [password, setPassword] = useState('')

  const [pwdErrorMsg, setPwdErrorMsg] = useState('')

  const [attachedAppIds, setAttachedAppIds] = useState<number[]>([])

  const proxyRedirect = useRedirectProxy()

  const {
    fetchUserRoleSelect,
    fetchUserTypeSelect,
    user_roles_options,
    user_types_options,
    createGlobalDashboardUserAsync,
    creatingGlobalDashboardUser
  } = useGlobalDashboardUserService()

  const phoneOptions = useCountryStore(state => state.phoneOptions)

  const { fetchPasswordPolicy, passwordPolicy } = usePasswordPolicyService()

  const enabled_policies = passwordPolicy.filter(d => d.is_enabled)

  const methods = useForm<TFormValues>({
    resolver: zodResolver(schema),
    defaultValues: DEFAULT_FORM_VALUES
  })

  const {
    control,
    handleSubmit,
    trigger,
    formState: { isDirty }
  } = methods

  const selectedAccountType = useWatch({ name: 'type.value', control })

  const selectedAccountName = useWatch({ name: 'name', control })

  const selectedAccountEmail = useWatch({ name: 'email', control })

  const filtered_user_roles_options = useMemo(() => {
    if (!selectedAccountType) return []

    return user_roles_options.filter(d => {
      const isServiceRole = d.value.includes('service')
      return selectedAccountType === ACCOUNT_TYPE.ADMIN
        ? !isServiceRole
        : isServiceRole
    })
  }, [selectedAccountType, user_roles_options])

  useMount(() => {
    fetchUserRoleSelect()
    fetchUserTypeSelect()
    fetchPasswordPolicy()
  })

  const onChangePassword = useCallback((pwd: string) => {
    setPassword(pwd)
    setPwdErrorMsg('')
  }, [])

  const onChangeAttachedAppIds = useCallback((ids: number[]) => {
    setAttachedAppIds(ids)
  }, [])

  const onCancel = useCallback(() => {
    proxyRedirect(ROUTE_NAMES.GLOBAL_DASHBOARD_USERS)
  }, [proxyRedirect])

  const onSuccess = useCallback((dashboardUserId: TID) => {
    redirect(ROUTE_NAMES.GLOBAL_DASHBOARD_USER_DETAIL, {
      params: { dashboardUserId }
    })
  }, [])

  const onBack = useCallback(() => {
    setStep(prev => prev - 1)
  }, [])

  const onNext = useCallback(async () => {
    const isValid = await trigger(['name', 'email', 'role', 'type'])

    const isPwdValid = checkPolicies(enabled_policies, password)

    if (!isPwdValid) {
      setPwdErrorMsg('Password must be valid with all password policies')
      return
    }

    if (isValid && isPwdValid) setStep(prev => prev + 1)
  }, [trigger, enabled_policies, password])

  const onSubmit = handleSubmit(
    ({ phone_code, role, type, ...rest }: TFormValues) => {
      const payload = {
        ...rest,
        phone_code: phone_code.value,
        role: role.value,
        type: type.value,
        password,
        applications: attachedAppIds
      }

      createGlobalDashboardUserAsync(payload).then(({ data }) => {
        onSuccess(data.id)
      })
    }
  )

  const isProcessing = creatingGlobalDashboardUser

  return (
    <>
      <Breadcrumb
        category={ROUTE_NAMES.GLOBAL_DASHBOARD_USERS}
        data={[{ name: 'Add Dashboard User' }]}
      />

      <span className="fixed inset-x-0 top-0 h-[200px] bg-[url('/cover.lossless.png')] bg-no-repeat bg-fixed bg-cover" />

      <form
        onSubmit={onSubmit}
        className="flex flex-col w-full max-w-[1056px] mx-auto gap-2 z-[1]"
      >
        {step === 1 && (
          <>
            <article
              className={cn(
                'flex flex-col rounded-lg',
                defaultBackground,
                defaultBorder
              )}
            >
              <header
                className="flex px-12 py-3 border-b"
                style={{ borderColor: colors.neutral[20] }}
              >
                <p
                  className="text-xl font-semibold"
                  style={{ color: colors.text.light }}
                >
                  Add New Dashboard User
                </p>
              </header>
              <article className="flex px-12 py-7 ">
                <article className="grid md:grid-cols-2 gap-16">
                  <article className="flex flex-col gap-1">
                    <p className="font-semibold">USER INFO</p>
                    <p
                      className="text-xs"
                      style={{ color: colors.neutral[50] }}
                    >
                      Access detailed information about each user to manage
                      their settings and permissions.
                    </p>
                  </article>
                  <article className="flex flex-col gap-5">
                    <Controller
                      name="type"
                      control={control}
                      render={({ field, fieldState: { error } }) => {
                        return (
                          <article className="flex flex-col gap-y-1">
                            <label className="text-sm font-medium">
                              Account Type
                              <Asterisk />
                            </label>
                            <Select
                              {...field}
                              options={user_types_options}
                              error={!!error?.message}
                            />
                            {error?.message && (
                              <p className="text-xs text-[--colors-danger-default]">
                                {error.message}
                              </p>
                            )}
                          </article>
                        )
                      }}
                    />

                    <Controller
                      name="role"
                      control={control}
                      render={({ field, fieldState: { error } }) => {
                        return (
                          <article className="flex flex-col gap-y-1">
                            <label className="text-sm font-medium">
                              Account Role
                              <Asterisk />
                            </label>
                            <Select
                              {...field}
                              options={filtered_user_roles_options}
                              error={!!error?.message}
                            />
                            {error?.message && (
                              <p className="text-xs text-[--colors-danger-default]">
                                {error.message}
                              </p>
                            )}
                          </article>
                        )
                      }}
                    />

                    <BaseHookFieldText
                      control={control}
                      name="name"
                      label="User Name"
                      required
                    />

                    <BaseHookFieldText
                      control={control}
                      name="email"
                      label="Email Address"
                      required
                    />

                    <article className="relative">
                      <Controller
                        name="phone_no"
                        control={control}
                        render={({ field }) => {
                          return (
                            <BaseText
                              label="Phone Number"
                              inputProps={{
                                ...field,
                                className: 'pl-[100px]'
                              }}
                            />
                          )
                        }}
                      />

                      <article className="absolute bottom-0 left-0 w-[100px]">
                        <Controller
                          name="phone_code"
                          control={control}
                          render={({ field }) => {
                            return (
                              <Select
                                value={field.value}
                                onChange={field.onChange}
                                options={phoneOptions}
                                type="sub"
                              />
                            )
                          }}
                        />
                      </article>
                    </article>
                  </article>
                </article>
              </article>
            </article>

            <PasswordManagement
              value={password}
              onChange={onChangePassword}
              passwordPolicies={enabled_policies}
              error={pwdErrorMsg}
            />
          </>
        )}

        {step === 2 && (
          <>
            {selectedAccountType !== ACCOUNT_TYPE.ADMIN && (
              <AttachAppToUser
                applications={attachedAppIds}
                onChange={onChangeAttachedAppIds}
              />
            )}

            {selectedAccountType === ACCOUNT_TYPE.ADMIN && (
              <article
                className={cn(
                  'flex justify-center rounded-lg px-12 py-7 w-full',
                  defaultBackground,
                  defaultBorder
                )}
              >
                <article className="flex flex-col items-center px-6 py-4 gap-6">
                  <DashboardAdminMessage />

                  <article className="inline-flex gap-2 items-center">
                    <Avatar size={32} />

                    <article>
                      <article className="inline-flex gap-1.5">
                        <p
                          className="font-medium text-sm"
                          style={{ color: colors.text.light }}
                        >
                          {selectedAccountName}
                        </p>

                        <span
                          className="inline-flex justify-center items-center px-1.5 py-px rounded text-xs font-medium"
                          style={{
                            background: colors.alphaSuccess[1],
                            color: colors.success[markedDefaultKey]
                          }}
                        >
                          Admin
                        </span>
                      </article>

                      <p
                        className="text-xs"
                        style={{ color: colors.neutral[60] }}
                      >
                        {selectedAccountEmail}
                      </p>
                    </article>
                  </article>
                </article>
              </article>
            )}
          </>
        )}

        <FooterAction>
          <article className="flex w-full max-w-[1056px] gap-2 mx-auto">
            {step === 2 && (
              <Button
                styleVariants={{ kind: 'neutral', type: 'outlined' }}
                disabled={isProcessing}
                onClick={onBack}
              >
                Back
              </Button>
            )}

            <article className="flex-1 flex justify-end gap-2">
              <Button
                disabled={isProcessing}
                styleVariants={{ type: 'text' }}
                onClick={onCancel}
              >
                Cancel
              </Button>

              {step === 1 && (
                <Button disabled={!isDirty} onClick={onNext}>
                  Next
                </Button>
              )}

              {step === 2 && (
                <Button type="submit" disabled={isProcessing}>
                  {isProcessing && <Spinner />}
                  Create
                </Button>
              )}
            </article>
          </article>
        </FooterAction>
      </form>

      <RedirectPrompt type="create" isDirty={isDirty} />
    </>
  )
}

export default GlobalDashboardUserCreate
