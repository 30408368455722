import { GENDER_OPTIONS } from '$app/constants'
import { cn } from '$app/utils'
import RedirectPrompt from '$blocks/RedirectPrompt'
import FooterAction from '$components/FooterAction'
import Loading from '$components/Loading'
import Select from '$components/Select'
import UserQueryMessage from '$components/UserQueryMessage'
import { useRedirectProxy, useRouteSummary } from '$contexts/RouteContext/hooks'
import {
  useAppUserService,
  useGlobalAppUserService,
  usePasswordPolicyService
} from '$hooks/services'
import { useCountryStore } from '$hooks/stores'
import {
  DEFAULT_FORM_VALUES,
  schema,
  TFormValues
} from '$pages/AppUser/constants'
import UserRoles from '$pages/AppUser/Create/Form/UserRoles'
import { ROUTE_NAMES } from '$router/config'
import { defaultBackground, defaultBorder } from '$styles/common.css'
import { Button, Spinner } from '@genie-fintech/ui/components'
import { BaseText, BaseTextarea } from '@genie-fintech/ui/components/fields'
import { themeVars } from '@genie-fintech/ui/style/theme'
import { zodResolver } from '@hookform/resolvers/zod'
import { useMount } from 'ahooks'
import { ArrowRight } from 'lucide-react'
import { useCallback, useState } from 'react'
import { Controller, useFieldArray, useForm, useWatch } from 'react-hook-form'
import PasswordManagement from '../../../../components/PasswordManagement'
import { checkPolicies } from '$pages/PasswordPolicy/constants'
import PreviewScreen from '../PreviewScreen'
import { TSavedUserInfo } from '../types'

const { colors } = themeVars

interface IProps {
  userId: string
  email: string
  onQueryAgain: VoidFunction
  onSuccess: (value: TSavedUserInfo) => void
}

const UserForm = ({ userId, email, onQueryAgain, onSuccess }: IProps) => {
  const [step, setStep] = useState(1)

  const [password, setPassword] = useState('')

  const [pwdErrorMsg, setPwdErrorMsg] = useState('')

  const {
    route: { params }
  } = useRouteSummary()

  const { appId } = params

  const proxyRedirect = useRedirectProxy()

  const phoneOptions = useCountryStore(state => state.phoneOptions)

  const { fetchGlobalAppUserDetailAsync, fetchingGlobalAppUserDetail } =
    useGlobalAppUserService()

  const {
    saveUserAsync,
    savingUser,
    attachRoleToUserAsync,
    attachingRoleToUser
  } = useAppUserService()

  const { fetchPasswordPolicy, passwordPolicy } = usePasswordPolicyService()

  const enabled_policies = passwordPolicy.filter(d => d.is_enabled)

  const methods = useForm<TFormValues>({
    resolver: zodResolver(schema),
    defaultValues: DEFAULT_FORM_VALUES
  })

  const {
    control,
    handleSubmit,
    reset,
    trigger,
    formState: { isDirty, errors }
  } = methods

  const { replace } = useFieldArray<TFormValues>({
    name: 'roles' as never,
    control
  })

  const selectedRoles = useWatch({ name: 'roles', control })

  const userName = useWatch({ name: 'name', control })

  useMount(() => fetchPasswordPolicy())

  useMount(() => {
    if (!userId) {
      reset({ ...DEFAULT_FORM_VALUES, email })
      return
    }
    fetchGlobalAppUserDetailAsync(userId).then(({ data }) => {
      const { gender, phone_code, phone_no, date_of_birth, ...rest } = data

      const modifiedData: TFormValues = {
        roles: [],
        gender: GENDER_OPTIONS.find(d => d.value === gender) ?? {
          label: '',
          value: ''
        },
        date_of_birth: date_of_birth ?? '',
        phone_code: { label: phone_code ?? '', value: phone_code ?? '' },
        phone_no: phone_no ?? '',
        ...rest
      }

      reset(modifiedData)
    })
  })

  const onChangePassword = useCallback((pwd: string) => {
    setPassword(pwd)
    setPwdErrorMsg('')
  }, [])

  const onCancel = useCallback(() => {
    proxyRedirect(ROUTE_NAMES.APP_USERS, { params })
  }, [proxyRedirect, params])

  const onBack = useCallback(() => {
    if (step === 1) {
      onQueryAgain()
    }
    setStep(prev => prev - 1)
  }, [step, onQueryAgain])

  const onNext = useCallback(async () => {
    const isValid = await trigger(['name', 'email', 'address'])

    const isPwdValid = userId ? true : checkPolicies(enabled_policies, password)

    if (!isPwdValid) {
      setPwdErrorMsg('Password must be valid with all password policies')
      return
    }

    if (isValid && isPwdValid) setStep(prev => prev + 1)
  }, [trigger, enabled_policies, password, userId])

  const onSubmit = handleSubmit(({ gender, phone_code, roles, ...rest }) => {
    if (!appId) return

    if (userId) {
      attachRoleToUserAsync(appId, userId, roles).then(() => {
        onSuccess({ name: userName, password })
      })
      return
    }

    saveUserAsync(appId, {
      password,
      gender: gender.value,
      phone_code: phone_code.value,
      roles,
      ...rest
    }).then(() => {
      onSuccess({ name: userName, password })
    })
  })

  const isProcessing = savingUser || attachingRoleToUser

  if (fetchingGlobalAppUserDetail) return <Loading />

  return (
    <>
      <form className="flex flex-col gap-1.5" onSubmit={onSubmit}>
        {step === 1 && (
          <>
            <article
              className={cn(
                'flex flex-col rounded-lg',
                defaultBackground,
                defaultBorder
              )}
            >
              <header
                className="flex px-12 py-3 border-b"
                style={{ borderColor: colors.neutral[10] }}
              >
                <p
                  className="text-xl font-semibold"
                  style={{ color: colors.text.light }}
                >
                  Add New User
                </p>
              </header>
              <article className="flex flex-col px-12 py-7 gap-10">
                <UserQueryMessage
                  title={
                    userId
                      ? 'This user is already in the CARROsso system.'
                      : 'This user is a new user for CARROsso system.'
                  }
                  type="success"
                >
                  <Button
                    onClick={onQueryAgain}
                    styleVariants={{ kind: 'success', size: 'small' }}
                  >
                    Change email or query again
                    <ArrowRight size={16} />
                  </Button>
                </UserQueryMessage>

                <article className="grid md:grid-cols-2 gap-16">
                  <article className="flex flex-col gap-1">
                    <p className="font-semibold">USER INFO</p>
                    <p
                      className="text-xs"
                      style={{ color: colors.neutral[50] }}
                    >
                      Access detailed information about each user to manage
                      their settings and permissions.
                    </p>
                  </article>

                  <article className="flex flex-col gap-5">
                    <article className="flex flex-col gap-5">
                      <Controller
                        name="name"
                        control={control}
                        render={({ field, fieldState: { error } }) => {
                          return (
                            <BaseText
                              label="User Name"
                              required
                              disabled={!!userId}
                              error={!!error?.message}
                              message={error?.message}
                              inputProps={{
                                ...field,
                                type: 'text',
                                disabled: !!userId
                              }}
                            />
                          )
                        }}
                      />

                      <Controller
                        name="date_of_birth"
                        control={control}
                        render={({ field, fieldState: { error } }) => {
                          return (
                            <BaseText
                              label="Date of Birth"
                              disabled={!!userId}
                              error={!!error?.message}
                              message={error?.message}
                              inputProps={{
                                ...field,
                                type: 'date',
                                disabled: !!userId
                              }}
                            />
                          )
                        }}
                      />

                      <Controller
                        name="gender"
                        control={control}
                        render={({ field, fieldState: { error } }) => {
                          return (
                            <article className="flex flex-col gap-y-1">
                              <label className="text-sm font-medium">
                                Gender
                              </label>
                              <Select
                                {...field}
                                options={GENDER_OPTIONS}
                                error={!!error?.message}
                                disabled={!!userId}
                              />
                              {error?.message && (
                                <p className="text-xs text-[--colors-danger-default]">
                                  {error.message}
                                </p>
                              )}
                            </article>
                          )
                        }}
                      />

                      <Controller
                        name="email"
                        control={control}
                        render={({ field }) => {
                          return (
                            <article className="flex flex-col gap-1">
                              <BaseText
                                label="Email"
                                required
                                disabled
                                inputProps={{
                                  value: field.value,
                                  readOnly: true,
                                  type: 'email',
                                  disabled: !!userId
                                }}
                              />

                              <article className="flex justify-end">
                                <Button
                                  styleVariants={{ type: 'text' }}
                                  onClick={onQueryAgain}
                                >
                                  Change Email
                                  <ArrowRight size={16} />
                                </Button>
                              </article>
                            </article>
                          )
                        }}
                      />

                      <article className="relative">
                        <Controller
                          name="phone_no"
                          control={control}
                          render={({ field }) => {
                            return (
                              <BaseText
                                label="Phone Number"
                                disabled={!!userId}
                                inputProps={{
                                  ...field,
                                  disabled: !!userId,
                                  className: 'pl-[100px]'
                                }}
                              />
                            )
                          }}
                        />

                        <article className="absolute bottom-0 left-0 w-[100px]">
                          <Controller
                            name="phone_code"
                            control={control}
                            render={({ field }) => {
                              return (
                                <Select
                                  value={field.value}
                                  onChange={field.onChange}
                                  options={phoneOptions}
                                  type="sub"
                                  disabled={!!userId}
                                />
                              )
                            }}
                          />
                        </article>
                      </article>

                      <Controller
                        name="address"
                        control={control}
                        render={({ field, fieldState: { error } }) => {
                          return (
                            <BaseTextarea
                              textareaProps={{ ...field }}
                              label="Address"
                              error={!!error?.message}
                              message={error?.message}
                              disabled={!!userId}
                            />
                          )
                        }}
                      />
                    </article>
                  </article>
                </article>
              </article>
            </article>

            {!userId && (
              <>
                <PasswordManagement
                  label="Create Password"
                  value={password}
                  onChange={onChangePassword}
                  passwordPolicies={enabled_policies}
                  error={pwdErrorMsg}
                />
              </>
            )}
          </>
        )}

        {step === 2 && (
          <UserRoles
            roles={selectedRoles}
            onChange={replace}
            errorMessage={errors.roles?.message}
            appId={appId}
          />
        )}

        {step === 3 && (
          <PreviewScreen name={userName} email={email} password={password} />
        )}

        {step < 3 && (
          <FooterAction>
            <article className="flex w-full max-w-[1056px] gap-2 mx-auto">
              <Button
                styleVariants={{ kind: 'neutral', type: 'outlined' }}
                disabled={isProcessing}
                onClick={onBack}
              >
                Back
              </Button>

              <article className="flex-1 flex justify-end gap-2">
                <Button
                  disabled={isProcessing}
                  styleVariants={{ type: 'text' }}
                  onClick={onCancel}
                >
                  Cancel
                </Button>

                {step === 1 && (
                  <Button disabled={!isDirty && !userId} onClick={onNext}>
                    Next
                  </Button>
                )}

                {step === 2 && (
                  <Button type="submit" disabled={isProcessing}>
                    {isProcessing && <Spinner />}
                    Create
                  </Button>
                )}
              </article>
            </article>
          </FooterAction>
        )}
      </form>

      <RedirectPrompt isDirty={true} type="create" />
    </>
  )
}

export default UserForm
