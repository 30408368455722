import { cn, generatePwd } from '$app/utils'
import Asterisk from '$components/Asterisk'
import { CopyButton } from '$components/CopyButon'
import PasswordPolicyChecker from '$components/PasswordPolicyChecker'
import { TPasswordPolicy } from '$services/api'
import { defaultBackground, defaultBorder } from '$styles/common.css'
import { Password } from '@genie-fintech/ui/components/fields'
import { themeVars } from '@genie-fintech/ui/style/theme'
import { markedDefaultKey } from '@genie-fintech/ui/style/theme/colors/functions'
import { useMount } from 'ahooks'
import { useCallback } from 'react'

const { colors } = themeVars

interface IProps {
  value: string
  label: string
  onChange: (pwd: string) => void
  passwordPolicies: TPasswordPolicy[]
  error?: string
}

const PasswordManagement = ({
  value,
  label,
  onChange,
  passwordPolicies,
  error
}: IProps) => {
  const onGenerate = useCallback(() => {
    const newPwd = generatePwd()
    onChange(newPwd)
  }, [onChange])

  useMount(() => {
    if (value) return
    onGenerate()
  })

  return (
    <article
      className={cn(
        'flex flex-col px-12 py-7 rounded-lg',
        defaultBackground,
        defaultBorder
      )}
    >
      <article className={cn('grid md:grid-cols-2 gap-16')}>
        <article className="flex flex-col gap-1">
          <p className="font-semibold">PASSWORD</p>
          <p className="text-xs" style={{ color: colors.neutral[50] }}>
            Update or reset your password to maintain account security and
            access control.
          </p>
        </article>

        <article className="flex flex-col gap-4">
          <article className="flex gap-1">
            <article className="flex-1 flex flex-col gap-1">
              <article className="flex items-center justify-between">
                <label className="text-sm font-medium">
                  {label}
                  <Asterisk />
                </label>

                <button
                  onClick={onGenerate}
                  type="button"
                  className="inline-flex text-sm font-medium"
                  style={{ color: colors.primary[markedDefaultKey] }}
                >
                  Generate
                </button>
              </article>

              <Password
                inputProps={{
                  value,
                  onChange: e => onChange(e.currentTarget.value),
                  autoComplete: 'new-password'
                }}
                error={!!error}
                message={error}
              />
            </article>

            <article className="flex items-end p-1">
              <CopyButton value={value} />
            </article>
          </article>

          <PasswordPolicyChecker pwd={value} policies={passwordPolicies} />
        </article>
      </article>
    </article>
  )
}

export default PasswordManagement
